/*-------------------------
    Banner
-------------------------*/
.banner {
    padding: 255px 0 0;
    position: relative;
    z-index: 2;
    min-height: 800px;
    @media #{$laptop-device} {
        padding: 220px 0 0;
    }
    @media #{$smlg-device} {
        padding: 200px 0 0;
        min-height: 100%;
    }
    
    @media #{$md-layout} {
        padding: 160px 0 0;
    } 
    @media #{$sm-layout} {
        padding: 150px 0 0;
    }

    .banner-content {
        @media #{$md-layout}{
            margin-bottom: 90px;
        }
        @media #{$sm-layout}{
            margin-bottom: 60px;
        }
        @media #{$large-mobile}{
            margin-bottom: 40px;
        }
        .title {
            font-size: 80px;
            font-weight: 700;
            color: var(--color-text-dark);
            @media #{$laptop-device} {
                font-size: 68px;
            }
            @media #{$smlg-device} {
                font-size: 60px;
            }
            @media #{$md-layout} {
                font-size: 55px;
            }
            @media #{$sm-layout} {
                font-size: 40px;
            }
        }
        .subtitle {
            font-size: var(--font-body-1);
            margin-bottom: 40px;
            width: 90%;
        }
        .axil-btn {
            padding: 28px 45px;
            box-shadow: 0 44px 84px -24px #5956e9;
            @media #{$md-layout} {
                padding: 20px 35px;
            }
        }
        .btn-group {
            display: flex;
            align-items: center;
            @media #{$large-mobile} {
                flex-direction: column;
                align-items: flex-start;
            }
            a {
                margin-right: 40px;
                @media #{$large-mobile} {
                    margin-right: 0;
                    margin-bottom: 30px;
                }
                &:last-child {
                    margin-right: 0;
                    margin-bottom: 0;
                }
            }
        }
        .about-btn {
            font-weight: 500;
            color: var(--color-primary);
            position: relative;
            padding-left: 50px;
            &::before {
                content: "";
                height: 1px;
                width: 34px;
                background-color: var(--color-primary);
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
                transition: var(--transition);
            }
            &:hover {
                &::before {
                    width: 100%;
                }
            }
        }
    }
    .banner-thumbnail {
        position: relative;
    }
    .banner-social {
        display: flex;
        align-items: center;
        .border-line {
            flex: 1;
            height: 1px;
            width: 100%;
            background-color: var(--color-body);
            margin-right: 30px;
            @media #{$large-mobile} {
                display: none;
            }
        }
        .social-icon {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            li {
                margin-right: 20px;
                &:last-child {
                    margin-right: 0;
                }
                a {
                    color: var(--color-body);
                    i, svg {
                        margin-right: 8px;
                        font-size: 20px;
                        color: var(--color-gray-4);
                        transition: var(--transition);
                    }
                    &:hover {
                        color: var(--color-primary);
                        i {
                            color: var(--color-primary);
                        }
                    }
                }
            }
        }
    }
    &.banner-style-1 {
        .banner-content {
            margin-top: 60px;
            @media #{$smlg-device} {
                padding-bottom: 100px;
            }
            @media #{$md-layout} {
                margin-top: 0;
                padding-bottom: 0;
            }
            .title {
                font-size: 78px;
                line-height: 1;
                @media #{$laptop-device} {
                    font-size: 68px;
                }
                @media #{$smlg-device} {
                    font-size: 60px;
                }
                @media #{$md-layout} {
                    font-size: 55px;
                }
                @media #{$sm-layout} {
                    font-size: 40px;
                }
            }
        }
        .banner-thumbnail {
            z-index: 5;
            .large-thumb {
                @media #{$laptop-device} {
                  margin-top: -60px;
                    img {
                        width: 85%;
                    }
                }
              //   @media #{$md-layout} {
              //     margin-top: -507px;
              // }
                @media #{$sm-layout} {
                  margin-top: 0px;
                    // display: none;
                }
            }
            .large-thumb-2 {
                text-align: right;
                margin-top: -260px;
                margin-right: -50px;
                @media #{$laptop-device} {
                    img {
                        width: 85%;
                    }
                }
                @media #{$smlg-device} {
                    margin-top: -200px;
                    margin-right: -30px;
                    img {
                        width: 80%;
                    }
                }
                @media #{$md-layout} {
                    margin-top: -260px;
                    margin-right: 0;
                }
                @media #{$sm-layout} {
                    margin-top: 0;
                    text-align: center;
                    position: absolute;
                    bottom: 0;
                }
            }
            .shape-group {
                .shape {
                    position: absolute;
                    &.shape-1 {
                        top: -25px;
                        right: 20px;
                        left: auto;
                        @media #{$laptop-device} {
                            right: 0;
                        }
                        @media #{$smlg-device} {
                            display: none;
                        }
                    }
                }
            }
        }
    }
    &.banner-style-2 {
        margin: 160px 95px 0;
        padding: 250px 0 300px 200px;
        background-color: #002C6E;
        border-radius: 60px;
        overflow: hidden;
        @media #{$laptop-device} {
            padding: 150px 0 200px 100px;
            min-height: 600px;
            margin: 130px 30px 0;
        }
        @media #{$smlg-device} {
            margin: 130px 30px 0;
            padding: 150px 60px;
            min-height: 600px;
        }
        @media #{$md-layout} {
            min-height: 100%;
            margin: 120px 30px 0;
        }
        @media #{$sm-layout} {
            margin: 120px 30px 0;
            padding: 80px 30px;
        }
        @media #{$large-mobile} {
            margin: 120px 15px 0;
        }
        .banner-content {
            margin-bottom: 0;
            .title {
                color: var(--color-white);
                margin-bottom: 40px;
            }
        }
    }
    &.banner-style-3 {
        padding: 220px 0 210px;
        @media #{$laptop-device} {
            padding: 180px 0 160px;
        }
        @media #{$smlg-device} {
            padding: 200px 0 150px;
        }
        @media #{$sm-layout} {
            padding: 150px 0 100px;
        }
        .banner-content {
            position: relative;
            z-index: 1;
            .subtitle {
                font-size: 20px;
                font-weight: 700;
                color: var(--color-rose);
                letter-spacing: 0.10em;
                font-family: var(--font-primary);
                margin-bottom: 30px;
                @media #{$md-layout} {
                   font-size: 16px;
                }
            }
            .title {
                margin-bottom: 60px;
                @media #{$md-layout} {
                    margin-bottom: 40px;
                }
            }
        }
        .banner-thumbnail {
           margin-right: -120px;
           text-align: right;
            @media #{$laptop-device} {
                margin-right: 0;
            }
            @media #{$smlg-device} {
                margin-right: 0;
            }
            @media #{$md-layout} {
                text-align: left;
            }
        }
    }
    &.banner-style-4 {
        background: linear-gradient(90deg, #ECF2F6 0%, rgba(236, 242, 246, 0) 70.31%);
        padding: 310px 0 100px;
        @media #{$laptop-device} {
            padding: 250px 0 100px;
        }
        @media #{$smlg-device} {
            padding: 250px 0 100px;
        }
        @media #{$md-layout} {
            padding: 180px 0 80px;
            min-height: 100%;
        }
        @media #{$sm-layout} {
            padding: 150px 0 60px;
            
        }
        .banner-content {
            margin-bottom: 200px;
            position: relative;
            z-index: 1;
            @media #{$smlg-device} {
                margin-bottom: 100px;
            }
            @media #{$md-layout} {
                margin-bottom: 0;
                
            }
            .title {
                width: 60%;
                margin-bottom: 50px;
                @media #{$laptop-device} {
                    font-size: 70px;
                    width: 55%;
                }
                @media #{$smlg-device} {
                    font-size: 70px;
                }
                @media #{$md-layout} {
                    font-size: 60px;
                    width: 100%;
                }
                @media #{$sm-layout} {
                    font-size: 50px;
                }
                @media #{$large-mobile} {
                    font-size: 40px;
                    margin-bottom: 30px;
                }
            }
            p {
                font-size: var(--font-body-1);
                width: 50%;
                margin-bottom: 40px;
                @media #{$md-layout} {
                   width: 100%;
                }
            }
        }
        .banner-thumbnail {
            position: absolute;
            top: 45%;
            right: 0;
            transform: translateY(-50%);
            @media #{$laptop-device} {
                right: -300px;
                img {
                    width: 85%;
                }
            }
            @media #{$smlg-device} {
                right: -570px;
                img {
                    width: 60%;
                }
            }
            @media #{$md-layout} {
                position: initial;
                transform: translateY(0);
                text-align: center;
                margin: -20px 0 20px;
                img {
                    width: 100%;
                }
            }
            @media #{$large-mobile} {
                margin: 0 0 20px;
            }
        }
    }
    &.banner-style-5 {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        padding: 310px 0 0;
        @media #{$laptop-device} {
            padding: 250px 0 0;
            min-height: 750px;
        }
        @media #{$smlg-device}{
            padding: 230px 0 0;
            min-height: 750px;
        }
        @media #{$md-layout}{
            padding: 170px 0 80px;
            min-height: 100%;
        }
        @media #{$sm-layout}{
            padding: 150px 0 60px;
            min-height: 100%;
        }
        &::after {
            content: "";
            height: 100%;
            width: 100%;
            background-color: rgba(41,41,48,0.8);
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: -1;
        }
        .banner-content {
            @media #{$md-layout}{
                margin-bottom: 40px;
            }
            .title {
                color: var(--color-white);
                margin-bottom: 50px;
                @media #{$smlg-device} {
                   font-size: 70px;
                }
                @media #{$md-layout} {
                   font-size: 60px;
                }
                @media #{$sm-layout} {
                   font-size: 50px;
                }
                @media #{$large-mobile} {
                   font-size: 40px;
                   margin-bottom: 40px;
                }
            }
            .axil-btn {
                color: var(--color-text-dark);
                box-shadow: none;
            }
        }
        .banner-form {
            display: flex;
            justify-content: flex-end;
            margin-bottom: -140px;
            @media #{$md-layout}{
                margin-bottom: 0;
                display: block;
            }
            .contact-form-box {
                max-width: 450px;
                @media #{$md-layout}{
                    max-width: 100%;
                }
                
            }
            .title {
                letter-spacing: -0.045em;
            }
        }
    }
}

.above-shape{
  width: 818px;
  height: 782px;
  border-radius: 50%;
  border: 107px solid #0a3c84;
  position: absolute;
  top: -417px;
  left: -257px;
  z-index: -1;
}
/* Common styles for shapes */
.yellow-line,.above-shape-2, .above-shape-3, .above-shape-4 ,.above-shape-5,.above-shape-6,.above-shape-7,.above-shape-8,.above-shape-9,.above-shape-10,.above-shape-11,.above-shape-12{
    width: 200px;
    height: 200px;
    position: absolute;
    animation-duration: 5s;
    animation-iteration-count: infinite;
  }
  
  /* Initial positioning for shapes */
  .above-shape-2 {
    left: 250px;
    top: 80px;
    animation-name: moveShape2;
  }
  
  .above-shape-3 {
    left: 670px;
    top: 120px;
    animation-name: moveShape3;
  }
  
  .above-shape-4 {
    left: 650px;
    bottom: 50px;
    animation-name: moveShape4;
  }
  
  .above-shape-5 {
    right: -53px;
    bottom: 12px;
    animation-name: moveShape4;
  }
  .above-shape-6 {
    bottom: -55px;
    right: -50px;
    z-index: 1;
  }
  .above-shape-7 {
    top: 120px;
    right: 150px;
    z-index: 1;
  }
  .above-shape-8{
    opacity: 0.1;
    top: -118px;
    right: -110px;
    width: 460px;
    height: 500px;
  }
  .above-shape-9{
    top: 72px;
    left:40px;
    animation-name:moveShape9; 
    animation-duration: 7s;
  } 
  .above-shape-10{
    right:0;
    bottom: -5px;
    animation-name: moveShape99;
    animation-duration: 7s;
  width: 150px;

  }
  .above-shape-11{
    right:25px;
    bottom: -25px;
    width: 150px;
    animation-name: moveShape9;
  }
  .above-shape-12{
    height: auto;
    bottom: -10px;
    width: 100vw;
  }
  .above-shape-13{
    position: absolute;
    right: 33px;
    width: 515px;
    top: 81px;
  }
  .above-shape-14{
    position: absolute;
    width: 200px;
    left: 0;
    bottom: -110px;
  }
  .yellow-line{
    width:100%;
    bottom:-151px;
  }
  
  /* Keyframes for animations */
  @keyframes moveShape2 {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-20px); }
  }
  
  @keyframes moveShape3 {
    0%, 100% { transform: translateX(0); }
    50% { transform: translateX(20px); }
  }
  
  @keyframes moveShape4 {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(20px); }
  }
  
  @keyframes moveShape9 {
    0%, 100% { transform: translateY(-80px); }
    50% { transform: translateY(60px); }
  }
  @keyframes moveShape99 {
    0%, 100% {
      transform: translateY(-300px) ;
    }
    50% {
      transform: translateY(10px) ; 
    }
  }
  
  /* Media queries for responsiveness */
  @media (max-width: 1200px) {
    .above-shape-2 {
      left: 200px;
      top: 70px;
    }
  
    .above-shape-3 {
      left: 500px;
      top: 100px;
    }
  
    .above-shape-4 {
      left: 550px;
      bottom: 40px;
    }
    .above-shape-6 {
        right: 0;
        bottom: 11px;
        width: 100px;
        height: 100px;
      }
      .above-shape-9 {
        top: 0;
     }
     .above-shape-10{
        bottom: -84px;
        width: 120px;
      }
      .above-shape-11{
        width: 120px;
        bottom: -88px;
      }
      .above-shape-12{
        bottom: -8px;
      }

      .yellow-line{
        width:100%;
        bottom:-172px;
      }
      .above-shape-14{
        position: absolute;
        width: 180px;
        left: 0;
        bottom: -128px;
      }
      .above-shape-13{
        right: 24px;
        top: 130px;
      }
  }
  
  @media (max-width: 992px) {
    .above-shape-2 {
      left: 150px;
      top: 60px;
    }
  
    .above-shape-3 {
      left: 400px;
      top: 80px;
    }
  
    .above-shape-4 {
      left: 450px;
      bottom: 30px;
    }
    .above-shape-6 {
        bottom: 11px;
        right: -3px;
        width: 100px;
        height: 100px;
      }
      .above-shape-7 {
        width: 120px;
        height: 120px;
        top: 57px;
        right: 20px;
    }
      .above-shape-9 {
       top: 0;
       width: 130px;
       animation: none;

    }
    .above-shape-5 {
      width: 115px;
      height: 140px;
      right: 0;
      }
      .above-shape-11{
        width: 160px;
        top: 72px;
        right: 65px;
      }
      .above-shape-12{
        // bottom: -5px;
      }
      .yellow-line{
        width:100%;
        bottom:-181px;
      }
      .above-shape-13{
        display: none;
      }
  }
  
  @media (max-width: 768px) {
    .above-shape-2, .above-shape-3, .above-shape-4 {
      width: 150px;
      height: 150px;
    }
  
    .above-shape-2 {
      left: 100px;
      top: 50px;
    }
  
    .above-shape-3 {
      left: 300px;
      top: 60px;
    }
  
    .above-shape-4 {
      left: 350px;
      bottom: 20px;
    }
    .above-shape-6 {
        top: 18px;
        right: 10px;
        width: 75px;
        height: 75px;
      }
      .above-shape-7 {
        top: 15px;
       width: 85px;
       height: 85px;
      }
      .above-shape-8{
        width: 400px;
        height: 400px;
      }
      .above-shape-9 {
        left: 0;
        width: 130px;
        animation: none;
 
     }
     .above-shape-11{
        top: 68px;
        width: 120px;
        right: 73px;
      }
      .yellow-line{
        width:100%;
        bottom:-183px;
      }
      .above-shape-14{
        width: 100px;
        bottom: -72px;
      }
      
  }
  
  @media (max-width: 576px) {
    .above-shape-2, .above-shape-3, .above-shape-4 {
      width: 100px;
      height: 100px;
    }
  
    .above-shape-2 {
      left: 50px;
      top: 40px;
    }
  
    .above-shape-3 {
      left: 200px;
      top: 40px;
    }
  
    .above-shape-4 {
      left: 250px;
      bottom: 10px;
    }
    .above-shape-8{
        width: 350px;
        height: 350px;
      }

      .above-shape-9 {
        width: 115px;
        animation: none;
        left: -9px;
 
     }
     .above-shape-11{
        right: 20px;
        width: 100px;
      }
  }
  

  /* Keyframes for rotation */
@keyframes rotateShape {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* New class for rotating animation */
  .rotate {
    animation-name: rotateShape;
    animation-duration: 4s; /* Adjust duration as needed */
    animation-iteration-count: infinite;
    animation-timing-function: linear; /* Smooth rotation */
  }


  @keyframes point {
    0%, 100% {
      transform: translateY(0) rotate(0deg);
    }
    50% {
      transform: translateY(-10px) rotate(-10deg);
    }
  }
  
  /* New class for pointing animation */
  .pointing-hand {
    animation-name: point;
    animation-duration: 2s; /* Adjust duration as needed */
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out; /* Smooth animation */
  }
  