/*-------------------------
    Team
-------------------------*/
.team-grid {
    text-align: center;
    margin-bottom: 80px;
    .thumbnail {
        margin-bottom: 30px;
        display: inline-block;
        max-width: 128px;
        a {
            position: relative;
            &:after {
                content: "";
                height: 100%;
                width: 100%;
                border: 8px dashed var(--color-rose);
                border-radius: 50%;
                position: absolute;
                left: 0;
                top: 0;
                transform: rotate(90deg);
                visibility: hidden;
                opacity: 0;
                transition: var(--transition);

            }
            img {
                border-radius: 50%;
            }
        }
    }
    .content {
        .title {
            color: var(--color-white);
            margin-bottom: 10px;
        }
        .designation {
            display: block;
            color: var(--color-gray-2);
        }
    }
    &:hover, &.active {
        .thumbnail {
            a {
               &:after {
                    visibility: visible;
                    opacity: 1;
                    transform: rotate(0deg);
               }
            }
        }
    }
}

.team-details-content {
    padding-right: 50px;
    margin-bottom: 30px;
    @media #{$sm-layout} {
       padding-right: 0;
    }

    .subtitle {
        display: block;
        color: var(--color-primary);
        border-bottom: 3px solid;
        font-weight: 700;
        font-size: 22px;
        line-height: 26px;
        padding-bottom: 20px;
        margin-bottom: 20px;
        @media #{$sm-layout} {
           font-size: 18px;
        }
        br {
            display: none;
        }
    }
    .social-share {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: -10px;
        li {
            margin: 10px;
            a {
                font-size: 20px;
                color: var(--color-gray-2);
                &:hover {
                    color: var(--color-primary);
                }
            }
        }
    }
    p {
        font-size: 20px;
        margin-top: 40px;
        line-height: 1.7;
    }
}

.team-details-thumb {
    img {
        border-radius: 10px;
    }
}

.avatar-container{
    display: inline-block;  
}

    .avatar-wrapper {
        margin-bottom:15px;
        width: 90px;
        height: 90px;
        border: 2px solid #78c9e8; /* Set the border color and thickness */
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%; /* Make the element circular */
        font-size: 2em; /* Adjust the font size as needed */
        color: white;  /* Adjust the text color as needed */
        background: transparent; /* Ensure the background is transparent */
        box-sizing: border-box; 
        transition: transform 0.6s cubic-bezier(0.25, 1, 0.5, 1), box-shadow 0.6s cubic-bezier(0.25, 1, 0.5, 1);
        span{
            font-weight: bold;
        }
      }

      .avatar-wrapper:hover {
        transform: scale(1.1); /* Slightly enlarge the avatar */
        box-shadow: 0 0 100px rgba(120, 201, 232, 0.7); /* Add a shadow effect */
        span{
            color: #78c9e8;
        }
      }
      