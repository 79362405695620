.mythosloader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full viewport height */
    background-color: white; /* White background */
  }
  
  .mythosloader img {
    animation: rotate 2s linear infinite; /* Rotating animation */
  }
  
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  